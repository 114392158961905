/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";
// import "../workspace/Work.css";
import "./profile.css";
import Cookies from "js-cookie";
import Weekly from "../agenda-calendario/Calendarioweek";

// eslint-disable-next-line no-unused-vars
export function Services({ session, usermod, nombre, apellido }) {
  //variables transitorias

  const { user_id } = useParams();

  //variables de trabajo
  const [action, setAction] = useState("");
  const [position, setPosition] = useState("");

  const [services, setServices] = useState({});
  const [selectedservice, setSelectedservice] = useState();
  const formato = new Intl.NumberFormat("es-ES", {
    style: "decimal",
    decimal: ",",
    thousand: ".",
  });
  const [name_service, setName_service] = useState(""); //Nombre del servicio
  const [type_service, setTypeservice] = useState(""); //tipo consulta Test otro
  // eslint-disable-next-line no-unused-vars
  const [service_comment, setServiceComment] = useState(""); //tipo consulta Test otro
  const [duration, setDuration] = useState(""); //duración minutos
  const [modality, setModality] = useState(""); //modalidad online preencial

  const [value_online, setValueOnline] = useState(""); //valor online

  const [value_offline, setValueOffline] = useState(""); //valor offline

  const [agregarservice, setAgregarservice] = useState(false); //valor offline

  const [serviceId, setServiceId] = useState(0);

  const eInfo = () => {
    setEditarinfo(!editar);
    setPosition("");
    setAgregarservice(false);
  };

  const eService = (position_, action_) => {
    setEditarstudy(!editarstudy);
    setPosition(position_);
    setAction(action_);
    setAgregarservice(false);

    setName_service("");
    setModality("");
    setTypeservice("");
    setDuration("");
    setValueOffline("");
    setValueOnline("");
  };

  const sendDataedit = (
    name_service_,
    modality_,
    type_service_,
    duration_,
    position_,
    value_offline_,
    value_online_,
  ) => {
    const vector = {
      action: action,
      profile_link: user_id,
      service_name: name_service === "" ? name_service_ : name_service,
      service_position: position_,
      service_type: type_service === "" ? type_service_ : type_service,
      service_mod: modality === "" ? modality_ : modality,
      service_duration: duration === "" ? duration_ : duration,
      value_online: value_online === "" ? value_online_ : value_online,
      value_offline: value_offline === "" ? value_offline_ : value_offline,
    };
    update_info(vector);
    setAction("");
    setPosition("");
    setEditarstudy(!editarstudy);
  };

  const dStudy = (action_, position_) => {
    setDeletestudy(!deletestudy);

    const vector = {
      action: action_,
      academic_type_position: position_,
      profile_link: user_id,
    };
    update_info(vector);
  };

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true); // Estado de carga

  const sService = (position) => {
    setSelectedservice(services[position]);
  };

  const [editar, setEditarinfo] = useState(false);
  const [editarstudy, setEditarstudy] = useState(false);
  const [deletestudy, setDeletestudy] = useState(false);

  const sAgreg = (value, setAgregar) => {
    setAction("new");
    setAgregarservice(false);
    setModality("Online");
    setAgregar(value);
    setEditarstudy(false);
  };

  const sendData = (setAG, setAgr, value, type_) => {
    const vector = {
      action: action,
      profile_link: user_id,
      service_name: name_service,
      service_type: type_service,
      service_mod: modality,
      service_duration: duration,
      service_comment: service_comment,
      value_online: value_online,
      value_offline: value_offline,
    };
    setAG(value, setAgr, type_);
    update_info(vector);
  };

  // eslint-disable-next-line no-unused-vars
  const update_info = (info) => {
    axios
      .post("/api/update_services", info, {
        withCredentials: true,
        headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
      })
      .then((response) => {
        setServices(response.data);
      })
      .catch((error) => console.log(error));
    console.log(info);
  };

  useEffect(() => {
    setServices(services);
  }, [services]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/servicesfeeder/${user_id}`);

        setServices(response.data);

        console.log("Respuesta servicesfeeder:", response.data);
      } catch (error) {
        console.error("Error al cargar servicesfeeder:", error);
      } finally {
        setLoading(false); // Finaliza la carga
      }
    };
    fetchData();
  }, []);

  const getCalendar = (service_id) => {
    console.log(service_id);
    setServiceId(service_id);
  }

  return (
    <div>
      <div style={{ padding: "15px" }}>
        <article
          className={`headers-academic ${services.length >= 1 || editar ? "show" : "notshow"}`}
        >
          {/* Map de BBDD */}
          {Object.keys(services).map((indx) => (
            <div
              key={indx}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <div
                className={`servicebox ${editarstudy === true && position === services[indx]["service_position"] ? "notshow" : "show"} ${selectedservice && selectedservice["service_position"] === services[indx]["service_position"] ? "selectedservice" : ""}`}
                style={{ minWidth: "70%" }}
                onClick={() => sService(indx)}
              >
                {
                  <>
                    <div
                      style={{ display: "flex" }}
                      className="pstudyindx indxdown"
                    >
                      <p className="pstudyindx">
                        {services[indx]["service_name"]}{" "}
                      </p>
                      <p>{" - "}</p>
                      <p
                        className="pstudyindx"
                        style={{ justifyContent: "left", marginLeft: "0%" }}
                      >
                        {" "}
                        Duración {services[indx]["service_duration"]} minutos
                      </p>
                    </div>

                    <div
                      style={{ display: "flex" }}
                      className="pstudyindx indxdown"
                    >
                      {services[indx]["service_mod"] === "Online" ? (
                        <p className="pstudyindx">
                          Valor: ${" "}
                          {formato.format(services[indx]["value_online"])}{" "}
                        </p>
                      ) : (
                        <p className="pstudyindx">
                          Valor: ${" "}
                          {formato.format(services[indx]["value_offline"])}{" "}
                        </p>
                      )}
                    </div>
                  </>
                }
                <div style={{ textAlign: "right" }}>
                  {/* <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={
                      "https://wa.me/56962420102?text=Hola quisiera una hora de atención con " +
                      nombre +
                      " " +
                      apellido
                    }
                    className="me-4 text-reset"
                    style={{ display: session ? "none" : "block" }}
                  > */}
                    <button className="boton-ovalado agendar" onClick={() => getCalendar(services[indx]["service_id"])}>
                      <b>Ver Horarios</b>{" "}
                      <i className="fa-regular fa-pen-to-square"></i>
                    </button>
                  {/* </a> */}
                </div>
              </div>

              {/* form editar */}
              <div style={{ display: "block" }}>
                <article
                  className={` ${editarstudy === true && position === services[indx]["service_position"] ? "show2" : "notshow"}`}
                >
                  <div className="serviceboxform">
                    <div>
                      <input
                        required
                        type="text"
                        className={`inputservicename`}
                        onChange={(e) => setName_service(e.target.value)}
                        placeholder={services[indx]["service_name"]}
                      />
                      <select
                        id="mySelect"
                        name="mySelect"
                        onChange={(e) => setTypeservice(e.target.value)}
                      >
                        <option value="Consulta">Consulta</option>
                        <option value="Evaluación">Evaluación / Test</option>
                        <option value="Otro">Otro</option>
                      </select>
                    </div>
                    <div>
                      Duración:
                      <input
                        required
                        type="text"
                        className={`inputservicenumber2`}
                        onChange={(e) => setDuration(e.target.value)}
                        placeholder={services[indx]["service_duration"]}
                      />{" "}
                      minutos
                      <span>
                        {" "}
                        - Modalidad:
                        <select
                          id="mySelect"
                          name="mySelect"
                          onChange={(e) => setModality(e.target.value)}
                          value={
                            !modality ? services[indx]["service_mod"] : modality
                          }
                        >
                          <option value="Online">Online</option>
                          <option value="Offline">Presencial</option>
                          <option value="Otro">Ambas</option>
                        </select>
                      </span>
                    </div>
                    <div style={{ marginLeft: "0%" }}>
                      <p
                        className={`inputservicenumber ${(!modality && (services[indx]["service_mod"] === "Online" || services[indx]["service_mod"] === "Otro")) || (modality && (modality == "Online" || modality == "Otro")) ? "show" : "notshow"}`}
                      >
                        Valor Online:
                      </p>
                      <input
                        required
                        type="text"
                        className={`inputservicenumber ${(!modality && (services[indx]["service_mod"] === "Online" || services[indx]["service_mod"] === "Otro")) || (modality && (modality == "Online" || modality == "Otro")) ? "show" : "notshow"}`}
                        onChange={(e) => setValueOnline(e.target.value)}
                        placeholder=""
                      />
                      <p
                        className={`inputservicenumber ${(!modality && (services[indx]["service_mod"] === "Offline" || services[indx]["service_mod"] === "Otro")) || (modality && (modality == "Offline" || modality == "Otro")) ? "show" : "notshow"}`}
                      >
                        Valor Presencial:
                      </p>
                      <input
                        required
                        type="text"
                        className={`inputservicenumber ${(!modality && (services[indx]["service_mod"] === "Offline" || services[indx]["service_mod"] === "Otro")) || (modality && (modality == "Offline" || modality == "Otro")) ? "show" : "notshow"}`}
                        onChange={(e) => setValueOffline(e.target.value)}
                        placeholder=""
                      />
                    </div>
                  </div>
                </article>

                {/* botones editar y borrar */}
                <p
                  className={session && editar ? "show" : "notshow"}
                  style={{ textAlign: "right" }}
                >
                  <i
                    className={`fa-solid fa-pen ${editarstudy === true && position === services[indx]["service_position"] ? "notshow" : "show"}`}
                    onClick={() => {
                      eService(services[indx]["service_position"], "edit");
                    }}
                    style={{ margin: "10px" }}
                  ></i>
                  <i
                    className={`fa-solid fa-trash ${editarstudy === true && position === services[indx]["service_position"] ? "notshow" : "show"}`}
                    onClick={() => {
                      dStudy("delete", services[indx]["service_position"]);
                    }}
                  ></i>

                  <button
                    className={`button-login profilebutton  ${editarstudy === true && position === services[indx]["service_position"] ? "show" : "notshow"}`}
                    style={{ textDecoration: "underline" }}
                    onClick={() => {
                      sendDataedit(
                        services[indx]["service_name"],
                        services[indx]["service_mod"],
                        services[indx]["service_type"],
                        services[indx]["service_duration"],
                        services[indx]["service_position"],
                        services[indx]["value_offline"],
                        services[indx]["value_online"],
                      );
                    }}
                  >
                    <i className={`fa-solid fa-floppy-disk `}></i> Guardar
                    cambios
                  </button>
                </p>
              </div>
            </div>
          ))}
        </article>

        {/* Formulario */}
        <article
          className={` ${editar && agregarservice ? "show" : "notshow"}`}
        >
          <div className="serviceboxform">
            <div>
              <input
                required
                type="text"
                className={`inputservicename ${agregarservice === true ? "show" : "notshow"}`}
                onChange={(e) => setName_service(e.target.value)}
                placeholder="Nombre de la prestación"
              />{" "}
              <span>
                {" "}
                - Tipo
                <select
                  id="mySelect"
                  name="mySelect"
                  onChange={(e) => setTypeservice(e.target.value)}
                >
                  <option value="Consulta">Consulta</option>
                  <option value="Evaluación">Evaluación / Test</option>
                  <option value="Otro">Otro</option>
                </select>
              </span>
            </div>

            <div>
              {" "}
              Duración:{" "}
              <input
                required
                type="text"
                className={`inputservicenumber ${agregarservice === true ? "show" : "notshow"}`}
                onChange={(e) => setDuration(e.target.value)}
                placeholder=""
              />{" "}
              minutos
              <span>
                {" "}
                - Modalidad:
                <select
                  id="mySelect"
                  name="mySelect"
                  onChange={(e) => setModality(e.target.value)}
                  defaultValue={"Online"}
                >
                  <option value="Online">Online</option>
                  <option value="Offline">Presencial</option>
                  <option value="Otro">Ambas</option>
                </select>
              </span>{" "}
            </div>

            <div>
              <p
                className={`inputservicenumber ${!modality || modality === "Online" || modality === "Otro" ? "show" : "notshow"}`}
              >
                Valor Online:
              </p>

              <input
                required
                type="text"
                className={`inputservicenumber ${agregarservice === true && (modality === "Online" || modality === "Otro") ? "show" : "notshow"}`}
                onChange={(e) => setValueOnline(e.target.value)}
                placeholder="pesos"
              />

              <p
                className={`inputservicenumber ${!modality || modality === "Offline" || modality === "Otro" ? "show" : "notshow"}`}
              >
                Valor presencial:
              </p>

              <input
                required
                type="text"
                className={`inputservicenumber ${agregarservice === true && (modality === "Offline" || modality === "Otro") ? "show" : "notshow"}`}
                onChange={(e) => setValueOffline(e.target.value)}
                placeholder="pesos"
              />
            </div>
          </div>
        </article>

        {/* botones nuevo */}
        <div style={{ textAlign: "right" }}>
          <button
            className={`button-login profilebutton ${editar === true && agregarservice === true ? "show" : "notshow"}`}
            onClick={() => sendData(sAgreg, setAgregarservice, false)}
            disabled={name_service.length < 1}
            style={{
              maxWidth: "fit-content",
              paddingLeft: "2%",
              paddingRight: "2%",
            }}
          >
            <i className="fa-solid fa-floppy-disk"></i> Guardar
          </button>

          <a
            className={`linkestudio ${editar && !agregarservice ? "show2" : "notshow"}`}
            onClick={() => sAgreg(true, setAgregarservice)}
            style={{ textDecoration: "underline" }}
          >
            <i className="fa-solid fa-plus"></i>
            <span style={{ margin: "5px" }}>Agregar nuevo</span>
          </a>
        </div>

        <div style={{ textAlign: "center" }}>
          <button
            className={`button-login profilebutton ${session && !editar ? "show" : "notshow"} `}
            onClick={() => {
              eInfo();
            }}
          >
            Agregar prestación
          </button>
        </div>
        <div style={{ textAlign: "center" }}>
          <button
            className={`button-login profilebutton ${editar ? "show" : "notshow"}`}
            onClick={eInfo}
          >
            Finalizar
          </button>
        </div>
      </div>
      {serviceId != 0 ? <Weekly serviceId={serviceId} /> : <></>}
    </div>
  );
}
