import axios from "axios";
import { useState } from "react";
import Cookies from "js-cookie";

export function CalendarPills(hours, loggedIn, setDate) {
  const [selectedHours, setSelectedHours] = useState("");

  const removeEvent = (event_id) => {
    axios
      .post(
        "/api/delete_timeslot",
        { id: event_id },
        {
          withCredentials: true,
          headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
        },
      )
      .then(() => console.log("removed"))
      .catch((error) => console.log(error));
  };

  return hours.map((day, idx) => (
    <div key={idx} className="card mx-2 dayweek">
      <div className="card-body dayweekbd">
        <h5 className="card-title wktitl">
          {day["date"].toLocaleDateString("es", {
            weekday: "short",
            day: "numeric",
          })}
        </h5>
        <ul className="list-unstyled">
          {day["hours"].map((hour, index) => (
            <li
              key={index}
              className={`rounded-pill px-3 py-1 my-1 ${selectedHours === hour["cal_id"] ? "selected-hour" : "default-hour"}`}
              onClick={() => setSelectedHours(hour["cal_id"])}
            >
              {new Date(hour["start"]).toLocaleString("es", {
                hour: "numeric",
                minute: "numeric",
              })}{" "}
              -{" "}
              {new Date(hour["end"]).toLocaleString("es", {
                hour: "numeric",
                minute: "numeric",
              })}
              <i
                className={`fa-solid fa-trash ${loggedIn ? "show" : "notshow"}`}
                onClick={() => {
                  removeEvent(hour["cal_id"]);
                  setDate(new Date(hour["start"]));
                }}
              ></i>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ));
}
