
/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import axios from "axios";
// import "../workspace/Work.css";
import "./profile.css";
import Cookies from "js-cookie";


export function Academic({ session}) {
  
  //variables transitorias
  
  const { user_id } = useParams();

  //variables de trabajo
  const [action,setAction]=useState("")
  const [position,setPosition]=useState("")
  const [type,setType]=useState("")
  const [name,setName]=useState("")
  const [institution,setInstitution]=useState("")
  const [year,setYr]=useState("")


  const [agregarpos, setAgregarpos] = useState(false);
  const [agregaruni, setAgregaruni] = useState(false);
  const [agregardiplo, setAgregardiplo] = useState(false);
  const [agregarcurso, setAgregarcurso] = useState(false);
  
  
  const eInfo = () => {
    setEditarinfo(!editar);
    setPosition("");
    setAgregarpos(false);
    setAgregardiplo(false);
    setAgregarcurso(false);
    setAgregaruni(false);

  };

  const eStudy = (type_,position_,action_) => {
    setEditarstudy(!editarstudy);
    setPosition(position_)
    setType(type_)
    setAction(action_)
    setAgregarpos(false)
    setAgregaruni(false)
    setAgregardiplo(false)
    setAgregarcurso(false)
    setName("")
    setInstitution("")
    setYr("")

  };
  const sendDataedit =(name_,institution_,year_,position_) =>{

    const vector = {'action':action,'profile_link':user_id,'academic_type_position':position_,'academic_name':name===""?name_:name,'academic_institution':institution===""?institution_:institution,'academic_yr':year===""?year_:year}
    update_info(vector);
    setAction("")
    setEditarstudy(!editarstudy)
    

  }

  const dStudy = (action_,position_) => {
    setDeletestudy(!deletestudy);

    const vector = {'action':action_,'academic_type_position':position_,'profile_link':user_id}
    update_info(vector);

  };


  // eslint-disable-next-line no-unused-vars

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true); // Estado de carga


  // const [variable,setVariable]=useState({})



   
   const [university, setUniversity] = useState([{}]);
   const [diplomado, setDiplomado] = useState([{}]);
   const [course, setCourse] = useState([{}]);
   const [posgrade, setPosgrade] = useState([{}]);






    const [editar, setEditarinfo] = useState(false);
    const [editarstudy, setEditarstudy] = useState(false);
    const [deletestudy, setDeletestudy] = useState(false);



    const sAgreg =(value,setAgregar,type_) =>{
      setAction("new")
      setAgregarcurso(false)
      setAgregardiplo(false)
      setAgregarpos(false)
      setAgregaruni(false)
      setAgregar(value)
      setEditarstudy(false)
      setType(type_)
      setName("")
      setName("")
      setName("")
    }
 


    const sendData =(setAG,setAgr,value,type_) =>{

    const vector = {'action':action,'academic_type':type,'profile_link':user_id,'academic_name':name,'academic_institution':institution,'academic_yr':year}
    setAG(value,setAgr,type_)
    update_info(vector);
    setName("");
    setInstitution("");
    setYr("");
  
  }



    // eslint-disable-next-line no-unused-vars
    const update_info = (info) => {
      axios
      
        .post("/api/update_academic_user_info", info,
          {
            withCredentials: true,
            headers: { 'X-CSRF-TOKEN': Cookies.get('csrf_access_token') },
            
        })
        .then((response) => {
   
          setPosgrade(response.data[0]);
          setUniversity(response.data[1])
          // setData(data);
          // 
          // )
          setCourse(response.data[3])
          setDiplomado(response.data[2])


        })
        .catch((error) => console.log(error));
        console.log(info)
    }

    useEffect(() => {
      setUniversity(university)
    }, [university]);
   
    useEffect(() => {
      setPosgrade(posgrade)
    }, [posgrade]);
    
    useEffect(() => {
      setDiplomado(diplomado)
    }, [diplomado]);

    useEffect(() => {
      setCourse(course)
    }, [course]);


    useEffect(() => {
      setName(name)
    }, [name]);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`/api/academicfeeder/${user_id}`);

          setUniversity(response.data[1])
          setPosgrade(response.data[0])
          setCourse(response.data[3])
          setDiplomado(response.data[2])
         
          console.log("Respuesta academicfeeder:", response.data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
        } finally {
          setLoading(false); // Finaliza la carga
        }
      };
      fetchData();  
    }, []);





      
    return (
<div>
<div style={{padding:"15px"}} >

<h5 style={{textAlign:"right"}}> <i className={`fa-solid fa-pen ${session &&!(editar) ? "show" : "notshow"} `} style={{fontSize:'13px'}} onClick={() => {eInfo()}} ></i></h5>


{/* Postgrado */}

<article className={`headers-academic ${posgrade.length>=1 || editar? "show" : "notshow"}`}>
<h5 className="title-sectionprofile"><i className="fa-solid fa-building-columns"></i> Magister o Doctorado </h5>


{/* Map de BBDD */}
{ Object.keys(posgrade).map((indx) => (
<div key={indx} style={{display:"flex", justifyContent:"space-between"}}>
  <div className={` ${editarstudy===true && type==="postgrade" && position===posgrade[indx]["academic_type_position"]? "notshow" : "show"}`} style={{minWidth:"70%"}}>
    {
      <>
        <span className="pstudyindx" style={{ display: "flex" }}>
          <i className="fa-solid fa-o" style={{fontSize:"10px"}}></i> 
          <p className="pstudyindx">{posgrade[indx]["academic_name"]}</p>
        </span>
        <div style={{ display: "flex" }} className="pstudyindx indxdown">
          <p className="pstudyindx">{posgrade[indx]["academic_institution"]}</p>
          <p>{" - "}</p>
          <p className="pstudyindx" style={{justifyContent:"left",marginLeft:"0%"}}>{posgrade[indx]["academic_yr"]}</p>
        </div>
      </>
    }
  </div>

  {/* form editar */}
  <article className={` ${( editarstudy===true && type==="postgrade" && position===posgrade[indx]["academic_type_position"])? "show" : "notshow"}`}><div>  

    <input
      required
      type="text"
      className={`inputacademicnae`}   

      onChange={(e) => setName(e.target.value)}
      placeholder={posgrade[indx]["academic_name"]}
      />  
    </div>
  <div>  <input
      required  
    type="text" 
      className={`inputacademicnae`} 

    onChange={(e) => setInstitution(e.target.value)}
    placeholder={posgrade[indx]["academic_institution"]}
    />  <input
    required
      type="text"
      className={`inputacademicyr`} 

    onChange={(e) => setYr(e.target.value)}
    placeholder={posgrade[indx]["academic_yr"]}
      /></div></article>

      {/* botones editar y borrar */}
  <p className={session &&(editar) ? "show" : "notshow"} style={{textAlign:"right"}}>
    <i 
      className={`fa-solid fa-pen ${editarstudy===true && type==="postgrade" && position===posgrade[indx]["academic_type_position"]?"notshow" : "show"}`}
      onClick={() => {eStudy("postgrade",posgrade[indx]["academic_type_position"],"edit")}} style={{margin:"10px"}}
    >
    </i>
    <i 
      className={`fa-solid fa-trash ${editarstudy===true && type==="postgrade" && position===posgrade[indx]["academic_type_position"]?"notshow" : "show"}`}
      onClick={() => {dStudy("delete",posgrade[indx]["academic_type_position"])}}
    >
    </i>
    <button 
      className={`button-login profilebutton  ${editarstudy===true && type==="postgrade" && position===posgrade[indx]["academic_type_position"]? "show" : "notshow"}`}
      style={{textDecoration:"underline"}}
      onClick={() => {sendDataedit(posgrade[indx]["academic_name"],posgrade[indx]["academic_institution"],posgrade[indx]["academic_yr"],posgrade[indx]["academic_type_position"])}}
    > 
      <i  className={`fa-solid fa-floppy-disk `}></i>
      Guardar cambios
    </button>
  </p>
</div>))}

                  

</article>



{/* Formulario */}
<article className={` ${editar ? "show" : "notshow"}`}><div>  <input
required
type="text"
className={`inputacademicnae ${agregarpos===true && type==="postgrade" ? "show" : "notshow"}`} 

onChange={(e) => setName(e.target.value)}
placeholder="Nombre del Posgrado "
/>  
</div>
<div>  <input
required
type="text" 
className={`inputacademicnae ${agregarpos===true && type==="postgrade"  ? "show" : "notshow"}`} 

onChange={(e) => setInstitution(e.target.value)}
placeholder="Institución "
/>  <input
required
type="text"
className={`inputacademicyr ${agregarpos===true && type==="postgrade"  ? "show" : "notshow"}`} 

onChange={(e) => setYr(e.target.value)}
placeholder="Año de titulación"
/></div></article>


{/* botones nuevo */}
<div style={{textAlign:"right"}}>
<button  className={`button-login profilebutton ${ editar===true && agregarpos===true && type==="postgrade"  ? "show" : "notshow"}`} 
onClick={()=>sendData(sAgreg,setAgregarpos,false,"postgrade")} 
disabled={name.length < 1}>

Guardar
</button>

<a  className={`linkestudio ${editar && !(agregarpos) ? "show2" : "notshow"}`} onClick={()=>sAgreg(true,setAgregarpos,"postgrade")} style={{textDecoration:"underline"}} >

<i className="fa-solid fa-plus"></i>
<span style={{margin:"5px"}}>Agregar nuevo</span>
</a>
</div>


{/* Universidad */}

<article className={`headers-academic ${university || editar? "show2" : "show"}`}>
<h5 className="title-sectionprofile"><i className="fa-solid fa-graduation-cap"></i> Pregrado</h5>



{/* Map de BBDD */}
{ Object.keys(university).map((indx) => (
              <div key={indx} style={{display:"flex", justifyContent:"space-between"}}>
                <div className={` ${editarstudy===true && type==="grade" && position===university[indx]["academic_type_position"]? "notshow" : "show"}`} style={{minWidth:"70%"}}>
                {<><span className="pstudyindx" style={{ display: "flex" }}><i className="fa-solid fa-o"style={{fontSize:"10px"}}></i>
                <p className="pstudyindx">{university[indx]["academic_name"]}</p>
                </span><div style={{ display: "flex" }} className="pstudyindx indxdown"><p className="pstudyindx">{university[indx]["academic_institution"]}</p>
                <p>{" - "}</p><p className="pstudyindx" style={{justifyContent:"left",marginLeft:"0%"}}>{university[indx]["academic_yr"]}</p></div></>
                }
                </div>

                {/* form editar */}
                <article className={` ${editarstudy===true && type==="grade" && position===university[indx]["academic_type_position"]? "show" : "notshow"}`}><div>  

                  <input
                    required
                    type="text"
                    className={`inputacademicnae`}   

                    onChange={(e) => setName(e.target.value)}
                    placeholder={university[indx]["academic_name"]}
                    />  
                  </div>
                <div>  <input
                    required  
                  type="text" 
                    className={`inputacademicnae`} 

                  onChange={(e) => setInstitution(e.target.value)}
                  placeholder={university[indx]["academic_institution"]}
                  />  <input
                  required
                    type="text"
                    className={`inputacademicyr`} 

                  onChange={(e) => setYr(e.target.value)}
                    placeholder={university[indx]["academic_yr"]}
                    /></div></article>

                    {/* botones editar y borrar */}
                <p className={session &&(editar) ? "show" : "notshow"} style={{textAlign:"right"}}>
                  <i  className={`fa-solid fa-pen ${editarstudy===true && type==="grade" && position===university[indx]["academic_type_position"]?"notshow" : "show"}`}
                onClick={() => {eStudy("grade",university[indx]["academic_type_position"],"edit")}} style={{margin:"10px"}}></i>
                <i className={`fa-solid fa-trash ${editarstudy===true && type==="grade" && position===university[indx]["academic_type_position"]?"notshow" : "show"}`}
                 onClick={() => {dStudy("delete",university[indx]["academic_type_position"])}}
                 >

                </i>
                <button className={`button-login profilebutton ${editarstudy===true && type==="grade" && position===university[indx]["academic_type_position"]? "show" : "notshow"}`}
               style={{textDecoration:"underline"}}
               onClick={() => {sendDataedit(university[indx]["academic_name"],university[indx]["academic_institution"],university[indx]["academic_yr"],university[indx]["academic_type_position"])}}> 
                <i  className={`fa-solid fa-floppy-disk `}
                
                >
                  </i> Guardar cambios</button>
                  </p>
                  </div>))}

                  

</article>



{/* Formulario */}
<article className={` ${editar ? "show" : "notshow"}`}><div>  <input
required
type="text"
className={`inputacademicnae ${agregaruni===true && type==="grade"  ? "show" : "notshow"}`} 

onChange={(e) => setName(e.target.value)}
placeholder="Título y/o especialidad"
/>  
</div>
<div>  <input
required
type="text" 
className={`inputacademicnae ${agregaruni===true && type==="grade" ? "show" : "notshow"}`} 

onChange={(e) => setInstitution(e.target.value)}
placeholder="Institución "
/>  <input
required
type="text"
className={`inputacademicyr ${agregaruni===true && type==="grade" ? "show" : "notshow"}`} 

onChange={(e) => setYr(e.target.value)}
placeholder="Año de titulación"
/></div></article>


{/* Guardar nueva entrada */}
<div style={{textAlign:"right"}}>
<button  className={`button-login profilebutton ${editar===true &&  agregaruni===true && type==="grade" ? "show" : "notshow"}`} 
onClick={()=>sendData(sAgreg,setAgregaruni,false,"grade")} 
disabled={name.length < 1}
>

Guardar
</button>

{/* Agregar neuva entrada */}
<a className={`linkestudio ${editar && !(agregaruni) ? "show2" : "notshow"}`} onClick={()=>sAgreg(true,setAgregaruni,"grade")}  style={{textDecoration:"underline" }}>
               
                
                <i  className={`fa-solid fa-plus `}
                
                >
                  </i> Agregar nuevo</a>




</div>



{/* Diplomado */}

<article className={`headers-academic ${diplomado.length>0 || editar ? "show2" : "notshow"}`} >
<h5 className="title-sectionprofile"><i className="fa-solid fa-award"></i> Diplomados y postitulos</h5>


{/* Map de BBDD */}
{ Object.keys(diplomado).map((indx) => (
              <div key={indx} style={{display:"flex", justifyContent:"space-between"}}>
                <div className={` ${editarstudy===true && type==="diplomado" && position===diplomado[indx]["academic_type_position"]? "notshow" : "show"}`}style={{minWidth:"80%"}}>
                {<><span className="pstudyindx" style={{ display: "flex" }}><i className="fa-solid fa-o"style={{fontSize:"10px"}}></i>
                <p className="pstudyindx">{diplomado[indx]["academic_name"]}</p>
                </span><div style={{ display: "flex" }} className="pstudyindx indxdown"><p className="pstudyindx" >{diplomado[indx]["academic_institution"]}</p>
                <p>{" - "}</p><p className="pstudyindx" style={{marginLeft:"0%"}}>{diplomado[indx]["academic_yr"]}</p></div></>
                }
                </div>

                {/* form editar */}
                <article className={` ${editarstudy===true && type==="diplomado" && position===diplomado  [indx]["academic_type_position"]? "show" : "notshow"}`}><div>  

                  <input
                    required
                    type="text"
                    className={`inputacademicnae`}   

                    onChange={(e) => setName(e.target.value)}
                    placeholder={diplomado[indx]["academic_name"]}
                    />  
                  </div>
                <div>  <input
                    required  
                  type="text" 
                    className={`inputacademicnae`} 

                  onChange={(e) => setInstitution(e.target.value)}
                  placeholder={diplomado[indx]["academic_institution"]}
                  />  <input
                  required
                    type="text"
                    className={`inputacademicyr`} 

                  onChange={(e) => setYr(e.target.value)}
                    placeholder={diplomado[indx]["academic_yr"]}
                    /></div></article>

                    {/* botones editar y borrar */}
                <p className={session &&(editar) ? "show" : "notshow"} style={{textAlign:"right"}}>
                  <i  className={`fa-solid fa-pen ${editarstudy===true && type==="diplomado" && position===diplomado[indx]["academic_type_position"]?"notshow" : "show"}`}
                onClick={() => {eStudy("diplomado",diplomado[indx]["academic_type_position"],"edit")}} style={{margin:"10px"}}></i>
                <i className={`fa-solid fa-trash ${editarstudy===true && type==="diplomado" && position===diplomado[indx]["academic_type_position"]?"notshow" : "show"}`}
                 onClick={() => {dStudy("delete",diplomado[indx]["academic_type_position"])}}
                 >

                </i>
                  <button className={`button-login profilebutton ${editarstudy===true && type==="diplomado" && position===diplomado[indx]["academic_type_position"]? "show" : "notshow"}`}
                style={{textDecoration:"underline"}}
                onClick={() => {sendDataedit(diplomado[indx]["academic_name"],diplomado[indx]["academic_institution"],diplomado[indx]["academic_yr"],diplomado[indx]["academic_type_position"])}}> 
                  <i  className={`fa-solid fa-floppy-disk `}
                  
                  >
                    </i> Guardar cambios</button>
                  
                  </p>
                  </div>))}

                  

</article>



{/* Formulario */}
<article className={` ${editar ? "show" : "notshow"}`}><div>  <input
required
type="text"
className={`inputacademicnae ${agregardiplo===true && type==="diplomado" ? "show" : "notshow"}`} 

onChange={(e) => setName(e.target.value)}
placeholder="Diplomado o Postitulo "
/>  
</div>
<div>  <input
required
type="text" 
className={`inputacademicnae ${agregardiplo===true && type==="diplomado" ? "show" : "notshow"}`} 

onChange={(e) => setInstitution(e.target.value)}
placeholder="Institución "
/>  <input
required
type="text"
className={`inputacademicyr ${agregardiplo===true && type==="diplomado" ? "show" : "notshow"}`} 

onChange={(e) => setYr(e.target.value)}
placeholder="Año de titulación"
/></div></article>


{/* guardar nueva entrada */}
<div style={{textAlign:"right"}}>
<button  className={`button-login profilebutton ${editar===true &&  agregardiplo===true && type==="diplomado" ? "show" : "notshow"}`}
 onClick={()=>sendData(sAgreg,setAgregardiplo,false,"diplomado")} 
 disabled={name.length < 1}>

Guardar
</button>

{/* agregar nueva entrada */}

<a className={`linkestudio ${editar && !(agregardiplo) ? "show2" : "notshow"}`} onClick={()=>sAgreg(true,setAgregardiplo,"diplomado")}  style={{textDecoration:"underline"}}>
               
                
                <i  className={`fa-solid fa-plus `}
                
                >
                  </i> Agregar nuevo</a>





</div>

{/* cursos - certificaciones */}
<article className={`headers-academic ${course.length>0 || editar ? "show2" : "notshow"}`}>
<h5 className="title-sectionprofile"><i className="fa-solid fa-book"></i> Cursos o certificaciones</h5>


{/* Map de BBDD */}
{ Object.keys(course).map((indx) => (
              <div key={indx} style={{display:"flex", justifyContent:"space-between"}}>
                <div className={` ${editarstudy===true && type==="course" && position===course[indx]["academic_type_position"]? "notshow" : "show"}`} style={{minWidth:"75%"}}>
                {<><span className="pstudyindx" style={{ display: "flex" }}><i className="fa-solid fa-o"style={{fontSize:"10px"}}></i>
                <p className="pstudyindx">{course[indx]["academic_name"]}</p>
                </span><div style={{ display: "flex" }} className="pstudyindx indxdown"><p className="pstudyindx">{course[indx]["academic_institution"]}</p>
                <p>{" - "}</p><p className="pstudyindx" style={{marginLeft:"0%"}}>{course[indx]["academic_yr"]}</p></div></>
                }
                </div>

                {/* form editar */}
                <article className={` ${editarstudy===true && type==="course" && position===course[indx]["academic_type_position"]? "show" : "notshow"}`}><div>  

                  <input
                    required
                    type="text"
                    className={`inputacademicnae`}   

                    onChange={(e) => setName(e.target.value)}
                    placeholder={course[indx]["academic_name"]}
                    />  
                  </div>
                <div>  <input
                    required  
                  type="text" 
                    className={`inputacademicnae`} 

                  onChange={(e) => setInstitution(e.target.value)}
                  placeholder={course[indx]["academic_institution"]}
                  />  <input
                  required
                    type="text"
                    className={`inputacademicyr`} 

                  onChange={(e) => setYr(e.target.value)} 
                    placeholder={course[indx]["academic_yr"]}
                    /></div></article>

                    {/* botones editar y borrar */}
                <p className={session &&(editar) ? "show" : "notshow"} style={{textAlign:"right"}}>
                  <i  className={`fa-solid fa-pen ${editarstudy===true && type==="course" && position===course[indx]["academic_type_position"]?"notshow" : "show"}`}
                onClick={() => {eStudy("course",course[indx]["academic_type_position"],"edit")}} style={{margin:"10px"}}></i>
                <i className={`fa-solid fa-trash ${editarstudy===true && type==="course" && position===course[indx]["academic_type_position"]?"notshow" : "show"}`}
                 onClick={() => {dStudy("delete",course[indx]["academic_type_position"])}}
                 >

                </i>
                <button className={`button-login profilebutton ${editarstudy===true && type==="course" && position===course[indx]["academic_type_position"]? "show" : "notshow"}`}
               style={{textDecoration:"underline"}}
               onClick={() => {sendDataedit(course[indx]["academic_name"],course[indx]["academic_institution"],course[indx]["academic_yr"],course[indx]["academic_type_position"])}}> 
                <i  className={`fa-solid fa-floppy-disk `}
                
                >
                  </i> Guardar cambios</button>
                  </p>
                  </div>))}

                  

</article>



{/* Formulario */}
<article className={` ${editar ? "show" : "notshow"}`}><div>  <input
required
type="text"
className={`inputacademicnae ${agregarcurso===true && type==="course" ? "show" : "notshow"}`} 

onChange={(e) => setName(e.target.value)}
placeholder="Curso o certificación "
/>  
</div>
<div>  <input
required
type="text" 
className={`inputacademicnae ${agregarcurso===true && type==="course" ? "show" : "notshow"}`} 

onChange={(e) => setInstitution(e.target.value)}
placeholder="Institución "
/>  <input
required
type="text"
className={`inputacademicyr ${agregarcurso===true && type==="course" ? "show" : "notshow"}`} 

onChange={(e) => setYr(e.target.value)}
placeholder="Año de titulación"
/></div></article>


{/* guardar nueva entrada */}
<div style={{textAlign:"right"}}>
<button  className={`button-login profilebutton ${editar===true &&  agregarcurso===true && type==="course" ? "show" : "notshow"}`} 
onClick={()=>sendData(sAgreg,setAgregarcurso,false,"course")}
disabled={name.length < 1} >

Guardar
</button>

{/* agregar nueva entrada */}
<a className={`linkestudio ${editar && !(agregarcurso) ? "show2" : "notshow"}`} onClick={()=>sAgreg(true,setAgregarcurso,"course")}  style={{textDecoration:"underline"}}>
               
                
                <i  className={`fa-solid fa-plus `}
                
                >
                  </i> Agregar nuevo</a>


</div>






            </div>
            <div style={{textAlign:"center"}}><button  className={`button-login profilebutton ${editar ? "show" : "notshow"}`} onClick={eInfo} >
          Finalizar
        </button></div>

            </div>
             
  );
}

