import React, { useState } from "react";

import axios from "axios";
import "./contact.css";
import "../login/login.css";

// eslint-disable-next-line react/prop-types
const Contact= ({color,color2}) => {
  // const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // const toggleMobileMenu = () => {
  //   setIsMobileMenuOpen(!isMobileMenuOpen);
  // };
  const [name, setName] = useState("");
  const [correo, setCorreo] = useState("");
  const [msj, setMsj] = useState("");
  const [formularioEnviado, setFormularioEnviado] = useState(false);

  const enviarFormulario = () => {
    // Datos a enviar en la solicitud POST
    const formData = {
      name: name,
      correo: correo,
      pswd: msj,
    };
    axios
      .post("/api/login", formData)
      .then((response) => {
        // Manejar la respuesta exitosa aquí
        console.log("Respuesta:", response.data);
        setFormularioEnviado(true);
        console.log(formularioEnviado);
      })
      .catch((error) => {
        // Manejar errores aquí
        console.error("Error:", error);
      });
  };
console.log(color)
  return (
    <section className="sectionbuton">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://wa.me/56962420102"
        className="me-4 text-reset"
          
      >
        <button className="botoncontacto" id="botoncontacto" style={{backgroundColor: color }}>
          <i className="fa-solid fa-comment"style={{color: color2 }}></i>
        </button>
      </a>
      <article className="Chatcontacto">
        {/* <article className={`Chatcontacto ${isMobileMenuOpen ? "chatopen" : ""}`}> */}
        <div className="form contact">
          <p className="titlecontact">Contacto</p>
          <form className="login-form">
            <input
              type="text"
              onChange={(e) => setName(e.target.value)}
              placeholder="Nombre"
            />
            <input
              type="email"
              onChange={(e) => setCorreo(e.target.value)}
              placeholder="Correo"
            />
            <textarea
              className="caja-contacto"
              placeholder="Mensaje"
              onChange={(e) => setMsj(e.target.value)}
            ></textarea>
            <button
              className="button-contact"
              onClick={enviarFormulario}
              style={{ marginLeft: "85%" }}
            >
              <i className="fa-solid fa-paper-plane"></i>
            </button>
          </form>
        </div>
      </article>
    </section>
  );
};

export default Contact;
