import axios from "axios";
import { useState } from "react";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import { addMinutes } from "date-fns";
import { es } from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import PropTypes from "prop-types";
import "./Calendario.css";
import Cookies from "js-cookie";

registerLocale("es", es);
setDefaultLocale("es", es);

export function EditCalendarModal({ services, setServices, setDate }) {
  EditCalendarModal.propTypes = {
    services: PropTypes.array,
    setServices: PropTypes.func,
    setDate: PropTypes.func,
  };

  const [startDate, setStartDate] = useState(new Date());
  const [addedMessage, setAddedMessage] = useState(false);
  const [repeat, setRepeat] = useState(false);
  const [repeatedDays, setRepeatedDays] = useState([
    { cap: "L", selected: false },
    { cap: "M", selected: false },
    { cap: "X", selected: false },
    { cap: "J", selected: false },
    { cap: "V", selected: false },
    { cap: "S", selected: false },
    { cap: "D", selected: false },
  ]);
  const semanalmente = "Semanalmente";

  const resetRepeatedDays = (position) => {
    const nextDays = repeatedDays.map((item, idx) => {
      if (position == idx) {
        return { ...item, selected: true };
      }
      return { ...item, selected: false };
    });
    setRepeatedDays(nextDays);
  };

  const updateSelectedRepeatedDates = (position) => {
    const nextRep = repeatedDays.map((item, idx) => {
      if (idx != position) {
        return item;
      } else {
        return {
          ...item,
          selected: !item.selected,
        };
      }
    });
    setRepeatedDays(nextRep);
  };

  const getRepeatedDays = () => {
    const repDays = [];
    repeatedDays.map((item) => {
      if (item.selected === true) {
        repDays.push(item.cap);
      }
    });
    return repDays;
  };

  const getCheckedServices = () => {
    const checkedServices = [];
    services.map((item) => {
      if (item.checked) {
        checkedServices.push(item);
      }
    });
    return checkedServices;
  };

  const checkSameDuration = () => {
    const durations = getCheckedServices().map((item) => item.service_duration);
    if (durations.length < 1) {
      return false;
    }
    return durations.every((item) => item === durations[0]);
  };

  const saveData = () => {
    if (services.every((item) => item.checked === false)) {
      setAddedMessage("Selecciona una prestacion");
    } else if (!checkSameDuration()) {
      setAddedMessage("Las prestaciones tienen diferente duración");
    } else {
      const data = {
        start_datetime: startDate.toISOString(),
        end_datetime: addMinutes(
          startDate,
          getCheckedServices()[0].service_duration,
        ).toISOString(),
        repeat: repeat,
        repeat_days: repeat ? getRepeatedDays() : [],
        services: getCheckedServices().map((item) => item.service_id),
      };
      console.log(data);
      axios
        .post("/api/add_timeslot", data, {
          withCredentials: true,
          headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
        })
        .then(() => {
          setAddedMessage("Guardado");
        })
        .catch((error) => console.log(error));
    }
  };

  const handleOnChange = (position) => {
    const handledServices = services.map((item, index) => {
      if (index == position) {
        return { ...item, checked: !item.checked };
      } else {
        return item;
      }
    });
    setAddedMessage(undefined);
    setServices(handledServices);
  };

  return (
    <div className="form">
      <div
        className="modal-header"
        style={{ height: "20px", padding: "3px" }}
      ></div>
      <form className="editarperfilmodal" onSubmit={(e) => e.preventDefault()}>
        <div className="mb-3">
          <i className="fa-regular fa-clock"></i>
          <DatePicker
            selected={startDate}
            onChange={(date) => {
              setAddedMessage(undefined);
              setStartDate(date);
              resetRepeatedDays((date.getDay() + 6) % 7);
            }}
            locale="es"
            showTimeInput
            dateFormat="Pp"
            minDate={new Date()}
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          {services != [] ? (
            services.map((item, idx) => {
              return (
                <div key={idx} className="display-block">
                  <input
                    className="display-inline-block"
                    type="checkbox"
                    id={`calendar-service-${idx}`}
                    checked={item.checked}
                    onChange={() => handleOnChange(idx)}
                  />
                  <label
                    className="display-inline-block"
                    htmlFor={`calendar-service-${idx}`}
                  >
                    {item.service_name} ({item.service_duration} minutos)
                  </label>
                </div>
              );
            })
          ) : (
            <p>Agrega alguna presatcion primero</p>
          )}
        </div>
        <div>
          <i className="fa-solid fa-rotate" />
          <select
            onChange={(e) => {
              setAddedMessage(undefined);
              e.target.value == semanalmente
                ? setRepeat(true)
                : setRepeat(false);
              resetRepeatedDays((startDate.getDay() + 6) % 7);
            }}
            style={{ marginBottom: "15px" }}
          >
            <option>No Repetir</option>
            <option>{semanalmente}</option>
          </select>
        </div>

        {repeat ? (
          <div className="repeated">
            {repeatedDays.map((item, idx) => {
              return (
                <button
                  key={`repeat-days-${idx}`}
                  style={
                    item.selected
                      ? { backgroundColor: "rgb(120, 180, 226)" }
                      : {}
                  }
                  onClick={() => updateSelectedRepeatedDates(idx)}
                >
                  <span>{item.cap}</span>
                </button>
              );
            })}
          </div>
        ) : (
          <></>
        )}

        <div>{addedMessage}</div>
        <div
          className="modal-header"
          style={{ height: "20px", padding: "3px" }}
        ></div>
        <button
          className="button-login"
          onSubmit={(e) => e.preventDefault()}
          type="button"
          onClick={saveData}
        >
          Agregar
        </button>
        <div
          className="modal-header"
          style={{ height: "20px", padding: "3px" }}
        ></div>
        <button
          className="button-login"
          data-dismiss="modal"
          onClick={() => setDate(new Date())}
        >
          Listo
        </button>
      </form>
    </div>
  );
}
