import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { UserContext } from "../login/login-context";
import Modal_ from "../Modals/Modal_";
import "./week.css";
import { EditCalendarModal } from "./EditCalendarModal";
import { CalendarPills } from "./CalendarPills";
import PropTypes from "prop-types";

function Weekly({serviceId}) {
  Weekly.propTypes = {
    serviceId: PropTypes.number,
  };
  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useContext(UserContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const { user_id } = useParams();

  useEffect(() => {
    if (user && user.logged_in && user.profile_link == user_id) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [user]);

  const [date, setDate] = useState(new Date());
  const [start_week, setStartWeek] = useState(new Date());
  const [end_week, setEndWeek] = useState(new Date());
  const [services, setServices] = useState([]);

  useEffect(() => {
    const start_week = new Date(date.toDateString());
    // El getDay() se basa en el Domingo (0) como inicio de semana, hay que hacer un ajuste
    // para comenzar la semana el lunes
    start_week.setDate(date.getDate() - ((date.getDay() + 6) % 7));
    const end_week = new Date(start_week);
    end_week.setDate(end_week.getDate() + 8);
    setStartWeek(start_week);
    setEndWeek(end_week);
  }, [date]);

  // eslint-disable-next-line no-unused-vars
  const [calendar, setCalendar] = useState([]);
  const [dailyCalendar, setDailyCalendar] = useState([]);

  useEffect(() => {
    axios
      .post("/api/calendarfeeder/" + user_id, {
        start_date: start_week,
        end_date: end_week,
        service_id: serviceId,
      })
      .then((response) => {
        setCalendar(response.data);
      })
      .catch((error) => console.log(error));
  }, [start_week, end_week, serviceId]);

  useEffect(() => {
    const cal = buildDailyCalendar();
    setDailyCalendar(cal);
  }, [calendar]);

  const buildDailyCalendar = () => {
    const cal = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(start_week);
      day.setDate(day.getDate() + i);
      const next_day = new Date(day);
      next_day.setDate(next_day.getDate() + 1);
      cal.push({
        date: day,
        hours: calendar.filter((event) => {
          const start = new Date(event["start"]);
          if (start >= day && start < next_day) {
            return true;
          }
          return false;
        }),
      });
    }
    return cal;
  };

  const updateServices = () => {
    axios
      .post(`/api/servicesfeeder/${user_id}`)
      .then((response) => {
        console.log(response.data);
        setServices(
          response.data.map((item) => {
            return { ...item, checked: false };
          }),
        );
      })
      .catch((error) => console.log(error));
  };

  return (
    <div
      className="container_week"
      style={{ maxWidth: "fit-content", padding: "0%" }}
    >
      <h5 className="title-sectionprofile pref redh5">
        Horario disponible /{" "}
        {start_week.toLocaleDateString("es", {
          month: "long",
          year: "numeric",
        })}
      </h5>
      <a
        href="#"
        data-toggle="modal"
        data-target="#editCalendarModal"
        onClick={() => updateServices()}
      >
        <button
          className={`button-login profilebutton ${loggedIn ? "show" : "notshow"}`}
        >
          Agregar Horario{" "}
          <i
            className={`fa-solid fa-plus ${loggedIn ? "show" : "notshow"}`}
            style={{ fontSize: "14px", height: "100%" }}
          ></i>
        </button>
      </a>
      <div
        className="d-flex justify-content-start"
        style={{ width: "98.4%", padding: "15px" }}
      >
        <span>
          <i
            className="fa-solid fa-angles-left"
            style={{ color: "#ffb013" }}
            onClick={() => {
              const newDate = new Date(date);
              newDate.setDate(newDate.getDate() - 7);
              setDate(newDate); // Para forzar la carga de la vista del calendario
            }}
          ></i>
        </span>
        {CalendarPills(dailyCalendar, loggedIn, setDate)}
        <span>
          <i
            className="fa-solid fa-angles-right"
            style={{ color: "#ffb013" }}
            onClick={() => {
              const newDate = new Date(date);
              newDate.setDate(newDate.getDate() + 7);
              setDate(newDate); // Para forzar la carga de la vista del calendario
            }}
          ></i>
        </span>
      </div>
      <Modal_ isOpen={true} id="editCalendarModal">
        <EditCalendarModal
          services={services}
          setServices={setServices}
          setDate={setDate}
        />
      </Modal_>
    </div>
  );
}

export default Weekly;
